import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_SENTRY_DSN, PUBLIC_ENVIRONMENT, PUBLIC_ENABLE_SENTRY } from "$env/static/public";
import { AppErrorCodes } from "$lib/utils/errorCodes";
import { LoggingService } from "$lib/logger";
import { sentryBeforeSend } from "$lib/sentry";

// hacky workaround due to s.th. like https://github.com/getsentry/sentry-javascript/issues/8377
(window.process as any) = {
    env: {
        NODE_DEBUG: false,
    },
};

try {
    Sentry.init({
        tunnel: "/api/sentry-tunnel",
        dsn: PUBLIC_SENTRY_DSN,
        environment: PUBLIC_ENVIRONMENT,
        sampleRate: PUBLIC_ENABLE_SENTRY === "true" ? 1 : 0,
        tracesSampleRate: PUBLIC_ENABLE_SENTRY === "true" ? 0.1 : 0,
        replaysSessionSampleRate: PUBLIC_ENABLE_SENTRY === "true" ? 1 : 0,
        replaysOnErrorSampleRate: PUBLIC_ENABLE_SENTRY === "true" ? 1 : 0,
        integrations: [Sentry.replayIntegration({ useCompression: false }), LoggingService.sentryLogIntegration],
        beforeSend: sentryBeforeSend,
    });
} catch (err) {
    LoggingService.error("could not initialize sentry on the client", AppErrorCodes.SentryInit);
}

export const handleError = Sentry.handleErrorWithSentry(({ error }: { error: unknown }) => LoggingService.error(error));
