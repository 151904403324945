import type { ErrorEvent, EventHint } from "@sentry/types";
import { IGNORE_ERROR_CODES } from "./const/ignoreErrors";

export function sentryBeforeSend(
    event: ErrorEvent,
    hint: EventHint
): ErrorEvent | PromiseLike<ErrorEvent | null> | null {
    if (hint) {
        const error = hint.originalException;
        let errorMessage: string | undefined;
        if (typeof error === "string") {
            errorMessage = error;
        } else if (error instanceof Error) {
            errorMessage = error.message;
        }

        if (errorMessage !== undefined) {
            if (
                IGNORE_ERROR_CODES.some((ignoreCode) => {
                    const { code, message } = ignoreCode;
                    const codeMatch = code === null;
                    const messageMatch = message !== null && message.test(errorMessage ?? "");
                    return codeMatch && messageMatch;
                })
            ) {
                return null;
            }
        }
    }
    return event;
}
